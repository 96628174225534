// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth/auth-context";
import { useLoading } from "../../context/loading-context";
import { useAsync } from "../../hooks/hooks";
import { useMutation, useQuery } from "react-query";
import Info from "../../components/info";

import { api_cms, handleUserResponse } from "../../context/auth/auth-provider";

export default function Login() {
  let history = useNavigate();

  const {
    mutate: login,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation(async (data) => api_cms.post("login", data), {
    onSuccess: (res) => {
      handleUserResponse(res);
      window.location.reload();
    },
  });

  const UsernameRef = useRef();
  const PasswordRef = useRef();

  const Empty = (e) => {
    const input = e.target;

    input.className = "";
  };
  function verifHomePage() {
    return /forzzago.com/g.test(
      window.location.href
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (isLoading) return;
    const { username, pswd } = event.target.elements;

    var go = true;
    if (username.value.length < 5) {
      UsernameRef.current.className = "input_textbox_error";
      go = false;
    }

    if (pswd.value.length < 5) {
      PasswordRef.current.className = "input_textbox_error";
      go = false;
    }

    if (go) {
      login({
        username: username.value,
        password: pswd.value,
      });
    }
  }

  return (
    <div
      className="ui-mobile-viewport ui-overlay-a bnormal"
      cz-shortcut-listen="true"
    >
      <div
        data-role="page"
        id="till-login"
        data-url="till-login_aspx"
        data-lang="fr"
        tabIndex="0"
        className="ui-page ui-page-theme-a ui-page-active"
        style={{ minHeight: "915px" }}
      >
        <div
          data-tap-toggle="false"
          data-role="header"
          data-theme="b"
          className="tw-header ui-header ui-bar-b"
          data-add-back-btn="false"
          data-back-btn-text="."
          role="banner"
        >
          <h1 className="tw-header-title ui-title" ariaLevel="1">
            <div
              style={{
                height: "30px",
                width: "150px",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <img
                  src={verifHomePage() === true ? '/forzza.png': `/logo.webp`}
                  style={{
                  display: "block",
                  height: "30px",
                  width: "150px",
                  margin: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </h1>
        </div>
        <div data-role="content" className="ui-content" role="main">
          {isError ? (
            <Info message={error.message} type="error" hideInfoView={reset} />
          ) : (
            <div id="twMainContentView">
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                id="adminLoginForm"
              >
                <ul
                  data-role="listview"
                  data-inset="false"
                  className="ui-listview"
                >
                  <li
                    data-role="list-divider"
                    className="tw-ui-li-divider ui-li-divider ui-bar-b ui-first-child"
                    data-theme="b"
                    style={{ textAlign: "center" }}
                  >
                    Sign In
                  </li>
                  <li
                    data-role="fieldcontain"
                    className="ui-field-contain ui-li-static ui-body-inherit"
                  >
                    <label
                      htmlFor="username"
                      id="usr"
                      style={{ fontSize: "14px" }}
                    >
                      Username
                    </label>
                    <div
                      className="ui-input-text ui-body-inherit  ui-mini ui-shadow-inset "
                      style={{ borderRadius: "10px" }}
                    >
                      <input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="username"
                        maxLength="20"
                        data-mini="true"
                        data-valgroup="login"
                        data-valfunction="loginUname"
                        data-clear-btn="true"
                        ref={UsernameRef}
                        onChange={(e) => {
                          Empty(e);
                        }}
                      />
                    </div>
                  </li>
                  <li
                    data-role="fieldcontain"
                    className="ui-field-contain ui-li-static ui-body-inherit"
                  >
                    <label htmlFor="password" style={{ fontSize: "14px" }}>
                      Password
                    </label>
                    <div
                      className="ui-input-text ui-body-inherit ui-mini ui-shadow-inset "
                      style={{ borderRadius: "10px" }}
                    >
                      <input
                        type="password"
                        name="pswd"
                        id="pswd"
                        placeholder="Password"
                        maxLength="40"
                        data-mini="true"
                        data-valgroup="login"
                        data-valfunction="loginPswd"
                        data-clear-btn="true"
                        ref={PasswordRef}
                        onChange={(e) => {
                          Empty(e);
                        }}
                      />
                    </div>
                  </li>
                  <li
                    data-role="fieldcontain"
                    className="ui-field-contain ui-li-static ui-body-inherit ui-last-child"
                    style={{ padding: ".5rem 30px" }}
                    
                  >
                    <div className="ui-btn ui-input-btn ui-btn-c ui-shadow ui-mini " >
                      Login
                      <input
                        type="submit"
                        value="Me connecter"
                        data-mini="true"
                        data-theme="c"
                        data-iconpos="right"
                      />
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          )}

          <div id="masterDetailsBox" className="fieldHide"></div>
        </div>
      </div>
    </div>
  );
}
